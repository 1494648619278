<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showSettingBtn="false"
          :showAddNew="true"
          title="Branch List"
          @onClickAddNewButton="onClickAddNewButton"
          @onClickSettingButton="onClickSettingButton"
      />
      <div class="row">
        <div class="col-10">
          <label for="colFormLabel" class="col-form-label">Search</label>
          <input class="form-control" type="text" name="status" v-model="quick_search">
        </div>
        <div class="col-2">
          <label for="">&nbsp;</label>
          <button
              style="min-width: 64px;margin-top: 1rem;"
              @click="onClickSearchButton"
              class="btn btn-primary waves-effect waves-float waves-light form-control"
          >Go
          </button>
        </div>
      </div>
    </div>

    <div class="col-12 mt-2">
      <div class="d-flex justify-content-end px-2">
        <button
            @click="exportTable"
            class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect"
        >
          Export
        </button>
      </div>
    </div>
    <ListTable
        :branches="branchList"
        @onEdit="onEditBranch"
    />
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </div>

    <BranchAddModal
        :modalTitle="branch.id ? 'Edit Branch' : 'Add Branch'"
        :showParent="true"
        ref="branch"
        :formData="branch"
        @onSubmitForm="handleBranchSubmit"
        @onCloseBranchModal="resetForm"
    />
  </div>
</template>

<script>
import TitleButton from '@/components/atom/TitleButton'
import ListTable
  from '@/components/molecule/procurement/organization-settings/admin-setup/branch/BranchListingTable.vue'
import BranchAddModal
  from '@/components/molecule/procurement/organization-settings/admin-setup/branch/BranchAddModal.vue'

import Pagination from '@/components/atom/Pagination'
import Loader from '@/components/atom/LoaderComponent'
import {inject} from "vue";
import handleBranches from "@/services/modules/procurement/organization-settings/branch";

export default {
  name: 'BranchList',

  components: {
    TitleButton,
    ListTable,
    BranchAddModal,
    Pagination,
    Loader,
  },

  data: () => ({
    loading: false,
    quick_search: null,
    offset: 20,
    branchList: [],
    branch: {
      name: '',
      address: null,
      email: null,
      contact_no: null,
      code: null,
    },
  }),

  computed: {
    companyId() {
      return this.$route.params.companyId
    },
  },

  methods: {
    onClickAddNewButton() {
      this.$refs.branch.toggleModal();
    },

    onClickSettingButton() {
      alert('Setting Button clicked');
    },

    onClickSearchButton() {
      alert('Search Button clicked');
    },

    resetForm() {
      this.branch = {
        name: '',
        address: null,
        email: null,
        contact_no: null,
        code: null,
      }
    },

    async handleBranchSubmit() {
      this.loading = true

      this.branch.company_id = this.$route.params.companyId;
      this.branch.user_id = this.$store.getters["user/getUser"].id;
      this.branch.updated_by = this.$store.getters["user/getUser"].id;

      try {
        let res = null;

        if (this.branch.id) {
          res = await this.updateBranch(this.branch.id, this.branch);
        } else {
          res = await this.createNewBranch(this.branch);
        }

        if (!res.status) {
          this.showError(res.message);
        } else {
          this.showSuccess(res.message);
          this.resetForm();
          this.onClickAddNewButton();
          await this.getBranches();
        }
      } catch (err) {
        if (!err.response) {
          this.showError('Something is wrong. Check your connectivity!!')
        } else if (err.response) {
          this.showError(err.response.message)
        }
      } finally {
        this.loading = false;
      }
    },

    async getBranches() {
      const query = this.getQuery();
      try {
        this.loading = true;
        let res = await this.fetchBranchList(query);
        if (!res.status) {
          this.resetPagination();
        } else if (res.status) {
          this.branchList = res.data;
        }
      } catch (err) {
        if (err.response) {
          this.showError(err.response.message)
        }
      } finally {
        this.loading = false
      }
    },

    getQuery() {
      let query = '?company_id=' + this.companyId
      query += '&offset=' + this.offset
      if (this.page) query += '&page=' + this.page
      return query
    },

    onPageChange(page) {
      let routeQuery = Object.assign({}, this.$route.query)
      routeQuery.page = page
      this.$router.push({path: this.$route.path, query: routeQuery})
      setTimeout(() => {
        this.getBranches();
      }, 100)
    },

    onEditBranch(data) {
      this.branch = data;
      this.onClickAddNewButton();
    },

    exportTable() {
      //todo
    },
  },

  setup() {
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');

    const {
      loading,
      fetchBranchList,
      createNewBranch,
      updateBranch,
    } = handleBranches()

    return {
      loading,
      showError,
      showSuccess,
      fetchBranchList,
      createNewBranch,
      updateBranch,
    }
  },

  mounted() {
    this.getBranches();
  }
}
</script>
