import Network from '@/services/network';
import {ref} from 'vue';

export default function handleBranches() {
    const network = new Network;
    const loading = ref(false);
    const uri = '/procurement/organization-setting/branch';

    const fetchBranchList = (query) => {
        return network.api('get', uri + query);
    }

    const createNewBranch = (data) => {
        return network.api('post', uri, data);
    }

    const updateBranch = (id, data) => {
        return network.api('put', `${uri}/${id}`, data);
    }

    return {
        loading,
        fetchBranchList,
        createNewBranch,
        updateBranch,
    }
}